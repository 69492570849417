<script lang="ts">
	import { getGlobalSettings } from "../getGlobalSettings.js";

	const { GOOGLE_TAG_MANAGER } = getGlobalSettings();

	/* eslint-disable */
</script>

<svelte:head>
	{@html `<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER}');</script>`}

	{@html `<noscript><iframe src=https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER}
	height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`}
</svelte:head>
